<template>
  <div>
    <b-form-group :label="label">
      <Datepicker
        class="pc-datetime form-control"
        :monday-first="mondayFirst"
        :placeholder="placeholder ? placeholder : label"
        :name="label"
        v-model="model"
        @input="input"
        :format="showFormat ? showFormat : format"
        :language="languages[langVal]"
        :state="state != undefined ? state : null"
        :class="
          state != undefined
            ? state
              ? 'border border-success'
              : 'border border-danger'
            : null
        "
      >
      </Datepicker>
    </b-form-group>
  </div>
</template>

// disable dates : https://codepen.io/jezmck/pen/dqpwmw

<script>
import * as lang from 'vuejs-datepicker/dist/locale';
import moment from 'moment';

export default {
  name: 'DateTime',
  props: {
    value: String | Date,
    valueFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    showFormat: String,
    label: String,
    placeholder: String,
    state: {
      type: Boolean,
      default: undefined,
    },
    langVal: {
      type: String,
      default: 'tr',
    },
    defaultValue: {
      type: String,
      default: '',
    },
    mondayFirst: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: '',
      languages: lang,
    };
  },
  created() {
    this.setDefaultValue();
  },
  computed: {
    format() {
      if (this.langVal == 'tr') return 'dd/MM/yyyy';
      else if (this.langVal == 'en')
        return moment(this.model).format('MM/DD/YYYY'); // Custom format for EN lang
    },
  },
  methods: {
    setDefaultValue() {
      this.model = this.defaultValue != '' ? this.defaultValue : '';
      if (this.model) this.input();
    },
    input() {
      this.$emit('input', moment(this.model).format(this.valueFormat));
    },
  },
  watch: {
    defaultValue() {
      this.setDefaultValue();
    },
    value(newVal) {
      this.model = newVal;
    },
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
  },
};
</script>

<style lang="scss">
.pc-datetime {
  input {
    width: 100%;
    outline: 0;
  }
  .vdp-datepicker__calendar .cell.selected {
    color: #fff;
    background: #2196f3;
  }
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid #2196f3;
  }
}
</style>
